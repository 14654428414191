<template>
  <a-modal v-model="visible" :title="edit?'编辑用户':'添加用户'" @cancel="$emit('close')" @ok="addMethod" okText="确定" cancelText="取消">
      <p class="flex">
        <label class="type-name">账号</label> 
        <a-input class="flex1" v-model="addUser.account" placeholder="账号" />
      </p>
      <p class="flex" v-if="!edit">
        <label class="type-name">密码</label> 
        <a-input class="flex1" v-model="addUser.pwd" placeholder="密码" />
      </p>
      <p class="flex">
        <label class="type-name">姓名</label> 
        <a-input class="flex1" v-model="addUser.userName" placeholder="姓名" />
      </p>
      <p class="flex">
        <label class="type-name">手机号</label> 
        <a-input class="flex1" v-model="addUser.cell" placeholder="手机号" :maxLength="11" />
      </p>
      <p>
        <label class="type-name">状态</label>
        <a-switch :checked="addUser.userStatus" @click="addUser.userStatus = !addUser.userStatus" />
      </p>
  </a-modal>
</template>
<script>
export default {
  props:{
    user:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    edit:false,
    companyId:'',
  },
  data(){
    return {
      addUser:{
        companyId:'100',
        userStatus:true,
        userStatusId:1
      },
      roleList:[],
      visible:true
    }
  },
  mounted(){
    if(this.user.userId){
      this.addUser = {userStatus:false}
      this.addUser.userName = this.user.userName
      this.addUser.account = this.user.account
      this.addUser.cell = this.user.cell
      this.addUser.statusName = this.user.statusName
      this.addUser.companyName = this.user.companyName
      this.addUser.statusId = this.user.statusId
      this.addUser.userStatus = this.user.statusId==1
      this.addUser.userId = this.user.userId
    }
    if(this.companyId)this.addUser.companyId = this.companyId
  },
  methods:{
    addMethod(){
      this.addUser.userStatusId = this.addUser.userStatus ? '1':'0'
      if(this.edit){
        this.editMethod();
        return
      }
      this.$post(this.$url.管理员添加, this.addUser).then(data => {
        this.visible = false;
        this.$tips('添加成功','管理员添加成功！','success')
        this.$emit('addSuccess')
      }).catch((err) => {
        this.$tips('添加失败','请重新添加管理员！','error')
      });
    },
    editMethod(){
      this.$post(this.$url.管理员编辑, this.addUser).then(data => {
        this.$tips('编辑成功','管理员编辑成功！','success')
        this.$emit('addSuccess')
        this.addUser={
          companyId:'100',
          userStatus:true,
          userStatusId:1
        }
      }).catch((err) => {
        this.$tips('编辑失败','请重新编辑管理员！','error')
      });
    }
  }
}
</script>