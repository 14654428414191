<template>
  <div>
    <!-- 用户列表 -->
    <a-button type="primary" @click="visible=true;edit=false;addUser={}" style="margin-bottom:10px;">添加用户</a-button>
    <a-table :columns="columns" :data-source="userList" :scroll="{ x: 1000, y: 600 }" :pagination="false">
      <span slot="action" slot-scope="index,item">
        <a-button type="link"  @click="editExam(item)">编辑</a-button>
        <span @click="remove(item)">删除</span>
      </span>
    </a-table>
    <div class="pagination">
      <a-pagination v-model="condition.pageNum" :pageSize="condition.pageSize" @change="changePage" :total="total" show-less-items />  
    </div> 
    <!-- 添加用户 -->
    <add-user v-if="visible" :user="addUser" :edit="edit" @addSuccess="getUser();visible=false" @close="visible=false" />
  </div>
</template>
<script>
import addUser from './components/addUser'
const columns = [
  { title: '用户名', width: 150, dataIndex: 'userName', key: 'userName'},
  { title: '账号', width: 150, dataIndex: 'account', key: 'account'},
  { title: '电话', width: 150, dataIndex: 'cell', key: 'cell' },
  { title: '状态', width: 150, dataIndex: 'statusName', key: 'statusName' },
  { title: '所属公司', width: 150, dataIndex: 'companyName', key: 'companyName' },
  { title: '操作', dataIndex: 'action', key: '8',scopedSlots: { customRender: 'action' }, },
];
export default {
  components:{
    addUser
  },
  data() {
    return {
      companyId:this.$store.state.companyId,
      visible:false,
      userList:[],
      columns,
      addUser:{},
      condition:{
        pageNum:1,
        pageSize:this.pageSize,
        companyId:this.$store.state.companyId
      },
      total:0,
      edit:false
    };
  },
  mounted(){
    this.getUser()
  },
  methods:{
    changePage(page){
      this.condition.pageNum = page ;
      this.getUser()
    },
    editExam(item){
      this.visible = true;
      this.edit = true;
      //this.addUser = Object.assign({},item)
      this.addUser.userName = item.userName
      this.addUser.account = item.account
      this.addUser.cell = item.cell
      this.addUser.statusName = item.statusName
      this.addUser.companyName = item.companyName
      this.addUser.statusId = item.statusId
      this.addUser.userId = item.userId
      
    },
    choiceKM(i){
      this.addExam.subjectId = i
    },
    getUser(){
      this.$post(this.$url.用户列表, this.condition).then(data => {
        this.userList = data.data.list
        this.total = data.data.total
      }).catch(() => {
          
      });
    },
    //删除
    remove(item){
      this.$confirm({
        title:'是否确认删除？',
        content:`删除管理员之后，该账号将无法操作！`,
        onOk:()=>{
          this.$post(this.$url.删除管理员, {
            userId:item.userId
          }).then(data => {
            this.$tips('删除成功','管理员账号删除成功！','success')
            this.getUser()
          }).catch(() => {
            this.$tips('删除失败','请重新操作！','error')
          });
        }
      })
    }
  }
};
</script>
<style scoped>

</style>